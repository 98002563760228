import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Loading from "../../utils/loading";
import image from "../../assets/images/daraz.png";
const HomeFooter = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(`https://admin.lining.com.np/api/home`);
      setData(response.data);
      console.log(response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  // if (loading) return <Loading />;
  // if (error) return "Error!";
  return (
    <>
      <footer>
        <div className="newsletter-form">
          <div className="container">
            <div className="row">
              <div className="form-title col-6">
                <h5>Subscribe to our newsletter now !!</h5>
                <p>For daily news and updates about Li-Ning Nepal.</p>
              </div>
              <div className="form-input col-6 ssss">
                <form>
                  <input
                    type="email"
                    name="Email Address"
                    id="email"
                    placeholder="Enter your email address"
                  />
                  <button className="subcribe-btn">Subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-3 abt-lining">
                <ul className="list-unstyled">
                  <li>
                    <h6>Get in Touch</h6>
                  </li>
                  <li>
                    <p>
                      <i className="fa-solid fa-home" />
                      <span>{Data?.site_settings?.site_name}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa-solid fa-location-dot" />
                      <span>{Data?.site_settings?.address}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa-solid fa-phone" />
                      <span>{Data?.site_settings?.mobile}</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-3">
                <ul className="list-unstyled">
                  <li>
                    <h6>Our stores</h6>
                  </li>
                  <li>
                    <a href="#">
                      <p>Main Branch</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p>Boudha Branch</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p>Durbar Marg Branch</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-3">
                <ul className="list-unstyled">
                  <li>
                    <h6>Customer Service</h6>
                  </li>
                  <li>
                    <a href="#">
                      <p>About Us</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p>Returns</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p>Delivery Information</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-3">
                <ul className="list-unstyled">
                  <li>
                    <h6>Follow Us</h6>
                  </li>
                  <li>
                    <a href={Data?.site_settings?.facebook}>
                      <p>
                        <i className="fa-brands fa-facebook" /> Facebook
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href={Data?.site_settings?.instagram}>
                      <p>
                        <i className="fa-brands fa-instagram" /> Instagram
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href={Data?.site_settings?.daraz}>
                      <p>
                        <img src={image} alt="Image" /> Daraz Nepal
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="lower-footer">
          <div className="container">
            <div className="row">
              <div className="col-4 copyright">
                <p>
                  &copy; copyright {new Date().getFullYear()} Li-Ning Inc. All
                  Rights Reserved.
                </p>
              </div>
              <div className="col-6 lower-footer-menu">
                <ul className="list-unstyled d-flex">
                  <li>
                    <a href="#">
                      <p>Guide</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p>Terms of Sales</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p>Terms of Use</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <p>Li-Ning Privacy Policy</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
    </>
  );
};
export default HomeFooter;
